export function initNavigation() {
    const mql = window.matchMedia('(max-width: 767px)');

    const sidebar = document.getElementById('toggle-sidebar');
    const sidebarTarget = sidebar
        ? document.getElementById(sidebar.getAttribute('aria-controls') as string)
        : null;
    const userMenuBtn = document.getElementById('toggle-user-menu');
    const userMenuTarget = userMenuBtn
        ? document.getElementById(userMenuBtn.getAttribute('aria-controls') as string)
        : null;
    const adminMenuBtn = document.getElementById('toggle-admin-menu');
    const adminMenuTarget = adminMenuBtn
        ? document.getElementById(adminMenuBtn.getAttribute('aria-controls') as string)
        : null;
    const staffMenuBtn = document.getElementById('toggle-staff-menu');
    const staffMenuTarget = staffMenuBtn
        ? document.getElementById(staffMenuBtn.getAttribute('aria-controls') as string)
        : null;
    const membersMenuBtn = document.getElementById('toggle-members-menu');
    const membersMenuTarget = membersMenuBtn
        ? document.getElementById(membersMenuBtn.getAttribute('aria-controls') as string)
        : null;

    const investmentsMenuBtn = document.getElementById('toggle-investments-menu');
    const investmentsMenuTarget = investmentsMenuBtn
        ? document.getElementById(investmentsMenuBtn.getAttribute('aria-controls') as string)
        : null;

    const menu = document.getElementById('menu');
    if (!menu) {
        // eslint-disable-next-line no-console
        console.warn("Expected to find required menu elements but didn't");
        return;
    }

    let hasMenuButton = false;

    function closeMenu(button, target) {
        button.setAttribute('aria-expanded', 'false');
        target.setAttribute('aria-hidden', 'true');
    }

    function toggleButton(button, target) {
        const expanded = button.getAttribute('aria-expanded') === 'true';
        button.setAttribute('aria-expanded', String(!expanded));
        target.setAttribute('aria-hidden', String(expanded));
    }

    function expandSidebar() {
        if (sidebar) {
            sidebar.setAttribute('aria-expanded', 'true');
            sidebar.parentElement && sidebar.parentElement.classList.remove('sidebar-collapsed');
        }
    }

    function toggleSidebar() {
        if (sidebar) {
            const expanded = sidebar.getAttribute('aria-expanded') === 'true';
            sidebar.setAttribute('aria-expanded', String(!expanded));
            sidebar.parentElement && sidebar.parentElement.classList.toggle('sidebar-collapsed');
        }
    }

    function attachMobileButton() {
        const menuButton = document.createElement('button');
        const wrapper = document.getElementsByClassName('nav--primary')[0];
        const span = document.createElement('span');
        menuButton.setAttribute('type', 'button');
        menuButton.setAttribute('id', 'menu-button');
        menuButton.setAttribute('aria-controls', 'menu');
        menuButton.setAttribute('aria-expanded', 'false');
        span.append('Menu');
        menuButton.append(span);
        menu?.setAttribute('aria-hidden', 'true');
        wrapper.insertBefore(menuButton, menu);
        hasMenuButton = true;
        menuButton.onclick = () => {
            toggleButton(menuButton, menu);
        };
    }

    if (userMenuBtn && userMenuTarget) {
        userMenuBtn.onclick = () => {
            toggleButton(userMenuBtn, userMenuTarget);
            expandSidebar();
        };
    }
    if (adminMenuBtn && adminMenuTarget) {
        adminMenuBtn.onclick = () => {
            toggleButton(adminMenuBtn, adminMenuTarget);
            expandSidebar();
        };
    }
    if (staffMenuBtn && staffMenuTarget) {
        staffMenuBtn.onclick = () => {
            toggleButton(staffMenuBtn, staffMenuTarget);
            expandSidebar();
        };
    }
    if (membersMenuBtn && membersMenuTarget) {
        membersMenuBtn.onclick = () => {
            toggleButton(membersMenuBtn, membersMenuTarget);
            expandSidebar();
        };
    }
    if (investmentsMenuBtn && investmentsMenuTarget) {
        investmentsMenuBtn.onclick = () => {
            toggleButton(investmentsMenuBtn, investmentsMenuTarget);
            expandSidebar();
        };
    }

    if (sidebar && sidebarTarget) {
        sidebar.onclick = () => {
            toggleSidebar();
        };
    }

    if (mql.matches) {
        attachMobileButton();
    }

    mql.addEventListener('change', event => {
        if (event.matches && !hasMenuButton) {
            attachMobileButton();
        } else if (hasMenuButton) {
            const domButton = document.getElementById('menu-button');
            domButton?.remove();
            menu.removeAttribute('aria-hidden');
            hasMenuButton = false;
        }
    });

    document.addEventListener('click', function clickListener(event) {
        if (userMenuBtn && userMenuTarget && !userMenuBtn.contains(event.target as Node)) {
            closeMenu(userMenuBtn, userMenuTarget);
        }
        if (adminMenuBtn && adminMenuTarget && !adminMenuBtn.contains(event.target as Node)) {
            closeMenu(adminMenuBtn, adminMenuTarget);
        }
        if (staffMenuBtn && staffMenuTarget && !staffMenuBtn.contains(event.target as Node)) {
            closeMenu(staffMenuBtn, staffMenuTarget);
        }
        if (membersMenuBtn && membersMenuTarget && !membersMenuBtn.contains(event.target as Node)) {
            closeMenu(membersMenuBtn, membersMenuTarget);
        }
        if (
            investmentsMenuBtn &&
            investmentsMenuTarget &&
            !investmentsMenuBtn.contains(event.target as Node)
        ) {
            closeMenu(investmentsMenuBtn, investmentsMenuTarget);
        }
    });
}
