// This must come first and we always import upfront (it's small and stripped in production)
import 'stop-runaway-react-effects/hijack';

import './core/pageRenderingObserver';

import { initNavigation } from './navigation';

// Any scripts can go here for django usage. Will need to be exposed globally.
// Ideally don't import anything directly here unless it's needed everywhere -
// dynamic imports only. This is to expose a minimal global API for
// interfacing with React that pulls things in when it needs them. We load this
// in the head so that DJANGO_REACT exists and can be called inline in templates before
// the body has finished rendering and document is loaded. The async nature of the
// function below (render, renderWidget) mean that it doesn't block and will render
// once the dependent javascript has loaded.
const components = {
    // Insert Components needed by Django in alphabetical order here:
    AccountTransactionCreateForm: async () =>
        (await import('./components/AccountTransactionCreateForm')).default,
    ApplicationApprovalButton: async () =>
        (await import('./components/ApplicationApprovalButton')).default,
    AuditLog: async () => (await import('./audit/AuditLog')).default,
    CurrencyFormatter: async () => (await import('./formatters/CurrencyFormatter')).default,
    DatePicker: async () => (await import('./components/django/Date')).default,
    DateTimePicker: async () => (await import('./components/django/DateTime')).default,
    DayDurationFormatter: async () => (await import('./formatters/DayDurationFormatter')).default,
    HijackButton: async () => (await import('./components/django/HijackButton')).default,
    QuillFormatter: async () => (await import('./quill-editor/QuillFormatter')).default,
    PercentageFormatter: async () => (await import('./formatters/PercentageFormatter')).default,
    Select: async () => (await import('./components/django/Select')).default,
    SessionExpiredModal: async () => (await import('./auth/SessionExpiredModal')).default,
    ServerChoicesSelectWidget: async () =>
        (await import('./components/django/ServerChoicesSelectWidget')).default,
    UploadWidget: async () => (await import('./components/UploadWidget')).default,

    /** Views **/
    AccountTransactionListView: async () =>
        (await import('./views/AccountTransactionListView')).default,
    AdminInvestmentApplicationListView: async () =>
        (await import('./views/AdminInvestmentApplicationListView')).default,
    MemberApplicationApplyView: async () =>
        (await import('./memberapplication/views/MemberApplicationApplyView')).default,
    MemberInvestmentProductView: async () =>
        (await import('./views/MemberInvestmentProductView')).default,
    InvestmentWithdrawalRequestListView: async () =>
        (await import('./views/InvestmentWithdrawalRequestListView')).default,
    MemberInvestmentAccountsView: async () =>
        (await import('./views/MemberInvestmentAccountsView')).default,
    InvestorNoteAccordion: async () => (await import('./components/InvestorNoteAccordion')).default,
    InvestmentOpportunitiesView: async () =>
        (await import('./views/InvestmentOpportunitiesView')).default,
    RetryMyobButton: async () => (await import('./components/django/RetryMyobButton')).default,
    ResourceView: async () => (await import('./views/ResourceView')).default,
    TermDepositCreateUpdateView: async () =>
        (await import('./views/TermDepositCreateUpdateView')).default,
    TermDepositMemberView: async () => (await import('./views/TermDepositMemberView')).default,
    TermDepositTermList: async () => (await import('./components/TermDepositTermList')).default,
};
const RootView = async () => (await import('./views/RootView')).default;
const WidgetWrapper = async () => (await import('./components/django/WidgetWrapper')).default;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.DJANGO_REACT = {
    /**
     * Render the specified component. When calling from a django template should be done
     * from the callback passed to DJANGO_DEFER. See react_component.html and defer.js.
     *
     * @param componentFn A component to render
     * @param props Any props to pass through to the component
     * @param selector The selector for the DOM element to render into. If not specified will render into a new
     * `div` inserted after the `script` tag this is called from.
     */
    async render(
        componentFn: () => Promise<React.ComponentType<any>>,
        props: Record<string, any>,
        selector: string
    ) {
        const el = document.querySelector(selector);
        if (!el) {
            throw new Error(`Target ${selector} not found`);
        }
        if (!window.__APP_CONTEXT__) {
            throw new Error(
                `__APP_CONTEXT__ missing. Make sure template contains {% render_app_context %}. See ${__DOCS_URL__}overview.html#django-integration`
            );
        }
        // Start these loading straight away
        const reactPromise = import('react');
        const reactDomPromise = import('react-dom');
        // We need to wait for config before loading anything else that may depend on it
        await import('./config');
        // Wait for everything to finish
        const [React, { render }, component, { default: AuthProvider }, devErrorBoundary] =
            await Promise.all([
                reactPromise,
                reactDomPromise,
                componentFn(),
                import('./auth/AuthProvider'),
                // In debug and when not using RootView load custom error handler to better
                // detect issues with render_component
                __DEBUG__ && componentFn !== RootView
                    ? import('./components/django/DevErrorBoundary')
                    : null,
            ]);
        if (typeof props === 'function') {
            props = await props(React);
        }
        let reactElement = (
            <React.Suspense fallback={null}>
                <AuthProvider>
                    {React.isValidElement(component)
                        ? component
                        : React.createElement(component, props)}
                </AuthProvider>
            </React.Suspense>
        );
        if (devErrorBoundary) {
            const { default: DevErrorBoundary } = devErrorBoundary;
            reactElement = <DevErrorBoundary>{reactElement}</DevErrorBoundary>;
        }
        render(reactElement, el);
    },
    /**
     * Render the specified widget with `onChange` and `value` provided.
     *
     * @param componentFn A component to render. This will be passed `value` & `onChange` to track the current value
     * set and all the values in `props`.
     * @param props Any props to pass through to the component
     * @param selector The selector for the DOM element to render into. If not specified will render into a new
     * `div` inserted after the `script` tag this is called from.
     */
    renderWidget(
        componentFn: () => Promise<React.ComponentType<any>>,
        props: Record<string, any>,
        selector: string
    ) {
        // NOTE: This function cannot be async; `render` relies on extracting
        // the current script tag at time of execution so must happen synchronously
        // otherwise the element will be inserted in the wrong place in the DOM.
        // Instead we pass props as a function which is called by `render` and we
        // can then resolve the component passed to WidgetWrapper.
        this.render(
            WidgetWrapper,
            async () => ({
                ...props,
                widgetComponent: await componentFn(),
            }),
            selector
        );
    },
    /**
     * Render standalone app. This sets up presto for use and is aimed at a view fully done in
     * React.
     *
     * @param componentFn A component to render as the child of RootView
     * @param props Any props to pass through to the component
     * @param selector The selector for the DOM element to render into. If not specified will render into a new
     * `div` inserted after the `script` tag this is called from.
     */
    renderApp(
        componentFn: () => Promise<React.ComponentType<any>>,
        props: Record<string, any>,
        selector: string
    ) {
        this.render(
            RootView,
            async React => ({
                children: React.createElement(await componentFn(), props),
            }),
            selector
        );
    },
    /**
     * Available list of components. Can be rendered like:
     *
     * ```html
     * <script>
     *     window.DJANGO_REACT.render(List, { items });
     * </script>
     * ```
     *
     * Or in a django template with the `render_component` tag - see react.py
     */
    components,
    /**
     * Init navigation, used to handle menu buttons for mobile and user menu.
     * See nav_primary.html template where it is called and navigation.ts
     * for the script.
     */
    initNavigation,
};
