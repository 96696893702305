import * as Sentry from '@sentry/browser';

function initSentry() {
    if (window.__APP_CONTEXT__?.sentry) {
        Sentry.init({
            dsn: window.__APP_CONTEXT__.sentry.dsn,
            environment: window.__APP_CONTEXT__.sentry.environment,
            release: __VERSION__,
        });

        Sentry.configureScope(scope => {
            if (window.__APP_CONTEXT__.authState.loggedIn) {
                scope.setUser({
                    id: window.__APP_CONTEXT__.authState.userId.toString(),
                    email: window.__APP_CONTEXT__.authState.email,
                });
            }
        });
    }
}

// Need to wait until document ready as script is loaded in head but
// __APP_CONTEXT__ only set at end of body
if (document.readyState !== 'loading') {
    initSentry();
} else {
    document.addEventListener('DOMContentLoaded', initSentry);
}
