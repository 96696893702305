// Wait for page rendering to be complete. This is not guaranteed to be correct and works in the following way:
// - If DOM is mutated wait a short time (500ms) before marking page as ready. If a subsequent mutation occurs before the
//   delay expires it starts again.
// - In progress `Endpoint` execute calls will prevent the page for being marked as done

// This is used by PDF conversion to track when the page has finished rendering.

const config = {
    subtree: true,
    childList: true,
};

let inProgress = 0;
let finished = false;
let observer;
const callbacks: (() => void)[] = [];

/**
 * Wait for promise `p` before considering page ready
 */
export async function waitFor(p: Promise<any>) {
    inProgress += 1;
    try {
        await p;
    } catch (e) {
        // ignore, what happens in promise isn't important
    }
    inProgress -= 1;
    requestAnimationFrame(() => {
        if (inProgress === 0 && !finished) {
            finished = true;
            callbacks.forEach(cb => cb());
            if (observer) {
                observer.disconnect();
            }
        }
    });
}

function setupMutationObserver() {
    function callback() {
        // eslint-disable-next-line no-use-before-define
        waitFor(
            new Promise(resolve => {
                window.setTimeout(() => {
                    requestAnimationFrame(resolve);
                }, 500);
            })
        );
    }

    observer = new MutationObserver(callback);
    observer.observe(document.getElementsByTagName('body')[0], config);
}

if (document.readyState !== 'loading') {
    setupMutationObserver();
} else {
    document.addEventListener('readystatechange', () => {
        if (document.readyState === 'interactive') {
            setupMutationObserver();
        }
    });
}

/**
 * Will call specified callback `cb` when page rendering has finished
 */
export function onFinish(cb: () => void): void {
    if (finished) {
        cb();
    } else {
        callbacks.push(cb);
    }
}
